import * as React from 'react'
import { Link } from 'gatsby'
import { Card, Collapse } from 'react-bootstrap'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ReactMarkdown from 'react-markdown'

import UpdatesList from './updates-list'

import { dontUnderline } from '../styles/common.module.css'
import { cardStackButton, cardImageRounding, statusCardBody } from './status-page-card.module.css'


class StatusPageCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            board: props.board,
            updatesList: props.updatesList,
            buttonText: "View all updates",
        };
    }

    handleClick() {

        if (this.state.collapse) {
            this.setState({ buttonText: "View all updates", collapse: !this.state.collapse });
        } else {
            this.setState({ buttonText: "Hide update history", collapse: !this.state.collapse });
        }

    }

    render() {
        return (
            <Card className={`shadow h-100 rounded text-center overflow-hidden`}>
                <div className="row g-0">
                    <div className="col-md-4">
                        <Link to={"/projects/" + this.state.board.slug}>
                            <Card.Img as={GatsbyImage} image={getImage(this.state.board.cover.localFile)} alt="" variant="start" className={`h-100 ${cardImageRounding}`} />
                        </Link>
                    </div>
                    <div className="col-md-8 text-md-start">
                        <Card.Body className={statusCardBody}>
                            <Link to={"/projects/" + this.state.board.slug} className={dontUnderline}>
                                <h3 className={`card-title cursor-pointer mb-1 mt-1`}>{this.state.board.boardname}</h3> {/* Link this to board itself */}
                            </Link>
                            {
                                this.state.updatesList.length > 0
                                    ?
                                    <div>
                                        <h5 className={`card-text pt-2 mb-1`}>Current state: {this.state.updatesList[0].state}</h5>
                                        <ReactMarkdown className="card-text pt-2 mb-1" children={this.state.updatesList[0].updatecontents} />
                                    </div>
                                    :
                                    <p className={`card-text pt-2 text-muted mb-1`}>No updates exist for this project.</p>
                            }

                        </Card.Body>
                    </div>
                </div>

                <ul className={`list-group list-group-flush`}>
                    {
                        this.state.updatesList.length > 0
                            ?
                            <div>
                                <span tabIndex={0} className={`list-group-item list-group-item-action border-0 user-select-none ${cardStackButton}`} role="button" onClick={() => this.handleClick()} onKeyDown={() => this.handleClick()}>
                                    {this.state.buttonText}
                                </span>
                                <Collapse in={this.state.collapse}>
                                    <div className="text-start">
                                        <UpdatesList updatesList={this.state.updatesList} />
                                    </div>
                                </Collapse>

                            </div>
                            :
                            <div>
                                <span disabled className={`disabled list-group-item list-group-item-action cursor-pointer border-0 mt-1 mb-1`}>
                                    No updates
                                </span>
                            </div>
                    }
                </ul>

                <Card.Footer className="text-muted"><small>Last updated {this.state.updatesList.length > 0 ? this.state.updatesList[0].changedate : "---"}</small></Card.Footer>
            </Card>
        )
    }
}

export default StatusPageCard