import * as React from 'react'
import { Table } from 'react-bootstrap'

import ReactMarkdown from 'react-markdown'


const UpdatesList = ({ updatesList }) => {

    return (

        <Table responsive="md" striped className={`m-0 rounded`}>
            <thead className={`table-dark rounded`}>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Update</th>
                </tr>
            </thead>
            <tbody>
                {
                    updatesList.map(update => (
                        <tr key={update.id}>
                            <td>{update.changedate}</td>
                            <td><ReactMarkdown children={update.updatecontents} /></td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>

    )
}
export default UpdatesList