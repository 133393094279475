import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, Nav, Card, Row, Col, Tab } from 'react-bootstrap'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'
import HeaderBlock from '../../components/header-block'
import FullUpdatesTable from '../../components/full-updates-table'
import StatusPageCard from '../../components/status-page-card'

import { animate } from '../../styles/common.module.css'


const StatusPage = ({ data }) => {

    return (
        <MainLayout pageTitle="Status">

            <Seo title="Status and Updates" description="View latest updates and status for ai03 Design Studio's projects" />

            <Container>
                <HeaderBlock header="Status and Updates">
                    <p>Use the toggles to view updates collectively or by board.</p>
                </HeaderBlock>
                <Tab.Container defaultActiveKey={data.allStrapiCategory.nodes[0].name}>
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Nav variant="pills" className={`flex-column`}>
                                <Nav.Item key={`centralized-updates`} className="me-0 me-lg-4">
                                    <Nav.Link className={`text-lg-start text-center rounded`} eventKey="centralized-updates" role="button">All Updates</Nav.Link>
                                </Nav.Item>
                                {
                                    data.allStrapiCategory.nodes.map(category => (
                                        <Nav.Item key={category.id} className="me-0 me-lg-4">
                                            <Nav.Link className={`text-lg-start text-center rounded`} eventKey={category.name} role="button">{category.name}</Nav.Link>
                                        </Nav.Item>
                                    ))
                                }
                            </Nav>
                        </Col>
                        <Col lg={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="centralized-updates" key="centralized-updates">
                                    <Row>
                                        <Col xs={12} className={animate}>
                                            <p className="text-center">Showing up to 25 most recent updates.</p>

                                            <Card className={`shadow rounded overflow-hidden mb-4`}>
                                                <FullUpdatesTable updatesList={data.allStrapiUpdate.nodes.slice(0, 25)} />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                {
                                    data.allStrapiCategory.nodes.map(category => (
                                        <Tab.Pane eventKey={category.name} key={category.id}>
                                            <Row>
                                                {
                                                    category.projects.sort((a, b) => {
                                                        let aUpper = a.boardname.toUpperCase();
                                                        let bUpper = b.boardname.toUpperCase();
                                                        return aUpper === bUpper ? 0 : (aUpper > bUpper ? 1 : -1);
                                                    }).map(board => (
                                                        <Col xs={12} key={board.id} className={`pb-4 ${animate}`}>
                                                            <StatusPageCard board={board} updatesList={data.allStrapiUpdate.nodes.filter(update => update.board.boardname === board.boardname)} />
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Tab.Pane>
                                    ))
                                }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>


        </MainLayout>
    )
}

export const query = graphql`
query {
    allStrapiCategory(sort: {fields: ordering, order: ASC}) {
        nodes {
            id
            name
            projects {
                id
                boardname
                slug
                publishdate(formatString: "YYYY-MM-DD")
                cover {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                aspectRatio: 1.618
                                transformOptions: {fit: COVER, cropFocus: CENTER}
                                placeholder: BLURRED
                                width: 400
                                quality: 95
                            )
                        }
                    }
                }
            }
        }
    }
    allStrapiUpdate(sort: {fields: changedate, order: DESC}) {
        nodes {
            id
            changedate(formatString: "YYYY-MM-DD")
            state
            updatecontents
            board {
                boardname
            }
        }
    }
}

`

export default StatusPage